import { React, useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import { useMediaQuery } from "./hook.js";
import linkContext from "../context/links/linkContext";
import { Box, Modal } from "@mui/material";
import { debounce } from "lodash";
import { IoMdArrowBack } from "react-icons/io";

const Link = (props) => {
  const location = useLocation().pathname.split("/");
  const param = location[location.length - 1];
  const { link, viewsetting } = props;

  const [shown, setShown] = useState(false);
  const context = useContext(linkContext);
  let { addClickInfo } = context;
  let { linksSettings } = viewsetting || {};

  const [platform, setPlatform] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [clickData, setClickData] = useState(false);
  const [linkid, setLinkid] = useState("");
  const [linkurl, setLinkUrl] = useState("");
  const [isHovered, setIsHovered] = useState(false); //1
  const [isHoveredIcon, setIsHoveredIcon] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");
  const [open, setOpen] = useState(false);
  const [titleName, setTitleName] = useState("");
  const [orientation, setOrientation] = useState(
    window.innerWidth > window.innerHeight ? "landscape" : "portrait"
  );

  const updateOrientation = () => {
    const newOrientation =
      window.innerWidth > window.innerHeight ? "landscape" : "portrait";
    setOrientation(newOrientation);
    setDeviceType(getDeviceType());
  };

  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/tablet|iPad/i.test(ua)) {
      return "tablet";
    } else if (/Mobile|Android|iPhone|iPod/i.test(ua)) {
      return "mobile";
    }
    return "desktop";
  };

  const [deviceType, setDeviceType] = useState(getDeviceType());

  useEffect(() => {
    window.addEventListener("resize", updateOrientation);
    window.addEventListener("orientationchange", updateOrientation);
    // Initial check
    updateOrientation();
    return () => {
      window.removeEventListener("resize", updateOrientation);
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, []);

  const handleOpen = (title) => {
    setTitleName(title);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const clickInfo = async (linkId, linkUrl) => {
    await fetch("https://geolocation-db.com/json/")
      // await fetch("https://ip-api.com/json/")
      .then((response) => response.json())
      .then((data) => {
        setCity(data.city);
        setCountry(data.country_name);
        setLatitude(data.latitude);
        setLongitude(data.longitude);
        // setCountry(data.country);
        // setLatitude(data.lat);
        // setLongitude(data.lon);
        setClickData(true);
        setLinkid(linkId);
        setLinkUrl(linkUrl);
        const deviceInfo = {
          userAgent: navigator.userAgent,
          platform: navigator.platform,
          language: navigator.language,
          screenWidth: window.screen.width,
          screenHeight: window.screen.height,
        };
        setPlatform(navigator.platform);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (clickData) {
      const data1 = {
        city: city,
        country: country,
        latitude: latitude,
        longitude: longitude,
        linkID: linkid,
        linkUrl: linkurl,
        platform: platform,
      };
      addClickInfo(data1);
      setClickData(false);
    }
  }, [clickData]);

  const DriveUrl = (logoUrl) => {
    let logolink = logoUrl;
    if (logoUrl?.indexOf("https://drive.google.com") > -1) {
      let id = logoUrl.match(/[-\w]{25,}/)[0];
      logolink = "https://lh3.google.com/u/0/d/" + id;
    }
    return logolink;
  };

  const DriveVideoUrl = (logoUrl) => {
    let logolink = logoUrl;
    if (logoUrl?.indexOf("https://drive.google.com") > -1) {
      let id = logoUrl.match(/[-\w]{25,}/)[0];
      logolink = "https://drive.google.com/file/d/" + id + "/preview";
    }
    return logolink;
  };

  const YoutubeUrl = (url) => {
    let originlURL = url;
    var regExp =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = originlURL.match(regExp);
    if (match && match[2].length == 11) {
      originlURL = "//www.youtube.com/embed/" + match[2];
      return originlURL;
    } else {
      return originlURL;
    }
  };

  const isRowBased = useMediaQuery("(min-width: 500px)");
  const mystyle = {
    container: (isRowBased) => ({
      padding: "5px",
      margin: "5px auto 0px auto",
      width: isRowBased ? "50%" : "90%",
    }),
  };

  const Renderlink = (props) => {
    const { link } = props;

    const isRowBased = useMediaQuery("(min-width: 500px)");
    const mystyle = {
      linksStyle: (isRowBased) => ({
        display: "flex",
        justifyContent: "center",
        padding: "5px",
        margin: "-5px auto 0px auto",
        width: isRowBased ? "45%" : "90%",
        height: isRowBased ? "200px" : "500px",
        backgroundColor: "#fff",
        borderTop: "2px solid FloralWhite",
        objectFit: "contain",
        borderRadius: "10px",
      }),
    };

    if (link.linkType === "Youtube Video") {
      return (
        <>
          <div style={mystyle.linksStyle(isRowBased)}>
            <iframe
              src={YoutubeUrl(link.instantLink || link.link)}
              title="Drive Video"
              allowFullScreen
              height="100%"
              width="100%"
              objectFit="contain"
              borderRadius="10px"
            />
          </div>
        </>
      );
    } else if (link.linkType === "Drive Video") {
      return (
        <>
          <div style={mystyle.linksStyle(isRowBased)}>
            <iframe
              src={DriveVideoUrl(link.instantLink)}
              title="Drive Video"
              allowFullScreen
              objectFit="contain"
              borderRadius="10px"
            />
          </div>
        </>
      );
    } else if (link.linkType === "Drive Image Link") {
      return (
        <>
          <div style={mystyle.linksStyle(isRowBased)}>
            <img
              src={DriveUrl(link.instantLink)}
              style={{
                height: "200px",
                width: "100%",
                objectFit: "contain",
                borderRadius: "10px",
              }}
              alt="images"
            />
          </div>
        </>
      );
    } else if (link.linkType === "Normal Image Link") {
      return (
        <>
          <div style={mystyle.linksStyle(isRowBased)}>
            <img
              src={link.instantLink}
              style={{
                height: "200px",
                width: "100%",
                objectFit: "contain",
                borderRadius: "10px",
              }}
              alt="images"
            />
          </div>
        </>
      );
    } else if (link.linkType === "Add Description") {
      return (
        <>
          <div
            style={{
              background: "#fff",
              borderTop: "2px solid FloralWhite",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "50px",
              margin: "auto",
              borderRadius: "10px",
              width: isRowBased ? "45%" : "75%",
              padding: "5px 10px",
              // wordBreak: "break-all",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            <p
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "#333",
                letterSpacing: "0.25px",
                lineHeight: "24px",
              }}
            >
              {link?.instantLink}
            </p>
          </div>
        </>
      );
    }
  };

  // styles // 2
  const baseStyle = {
    background: `${
      viewsetting?.linksSettings?.linksBackgroundColor ||
      linksSettings?.background ||
      ""
    }`,
    width: "auto",
    margin: "auto auto 5px auto",
    textAlign: "center",
    border: `1px solid ${viewsetting?.linksSettings?.linksBorderColor || ""}`,
    borderRadius: `${viewsetting?.linksSettings?.borderRadius || "50px"}`,
    boxShadow: `${viewsetting?.linksSettings?.boxShadow || ""}`,
    color: viewsetting?.linksSettings?.arrowsColor || "",
  };

  const iconBaseStyle = {
    color: viewsetting?.linksSettings?.arrowsColor || "",
    strokeWidth: "2px",
    fontWeight: "bold",
  };
  const hoverIconStyle = {
    ...iconBaseStyle,
    color: viewsetting?.linksSettings?.arrowsHoverColor || "",
  };

  const hoverStyle = {
    ...baseStyle,
    Cursor: "pointer",
    background: ` ${
      viewsetting?.linksSettings?.linksBackgroundColor ||
      viewsetting?.linksSettings?.color
    }`,
    transition: `${viewsetting?.linksSettings?.transition}`,
    transform: `${viewsetting?.linksSettings?.transform}`,
  };

  const linkHeadingBaseStyle = {
    marginBottom: "0",
    color: `${
      viewsetting?.linksSettings?.linksTitleColor ||
      viewsetting?.linksSettings?.color
    }`,
    fontSize: `${linksSettings?.size || ""}`,
    fontFamily: `${linksSettings?.family || ""}`,
  };

  const linkHeadingHoverStyle = {
    ...linkHeadingBaseStyle,
    color: `${
      viewsetting?.linksSettings?.linksTitleHoverColor ||
      viewsetting?.linksSettings?.background
    }`,
  };

  const handleLinkClick = debounce((linkId, linkUrl, title) => {
    clickInfo(linkId, linkUrl);
    handleOpen(title);
    handleRedirectToIframe(linkUrl);
  }, 100);

  const handleRedirectToIframe = (url) => {
    let convertLink = convertToEmbeddedLink(url);
    setIframeUrl(convertLink);
  };

  const convertToEmbeddedLink = (url) => {
    const youtubeRegex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const youtubeMatch = url.match(youtubeRegex);

    const driveRegex =
      /(?:https?:\/\/)?(?:drive\.google\.com\/(?:file\/d\/|open\?id=|uc\?id=|thumbnail\?id=))([a-zA-Z0-9_-]{33,})/;
    const driveMatch = url.match(driveRegex);

    if (youtubeMatch && youtubeMatch[1]) {
      const videoId = youtubeMatch[1];
      return `https://www.youtube.com/embed/${videoId}`;
    }

    if (driveMatch && driveMatch[1]) {
      const fileId = driveMatch[1];
      if (
        url.includes("file/d/") ||
        url.includes("open?id=") ||
        url.includes("uc?id=")
      ) {
        return `https://drive.google.com/file/d/${fileId}/preview`;
      } else if (url.includes("thumbnail?id=")) {
        return `https://drive.google.com/thumbnail?id=${fileId}`;
      }
    }

    return url;
  };

  return (
    <>
      <div style={mystyle.container(isRowBased)} className="linksDiv">
        <div
          id="logoImage"
          className={
            "d-flex justify-content-between p-2 link animation" + link.animation
          }
          style={isHovered ? hoverStyle : baseStyle}
          onMouseEnter={() => {
            setIsHovered(true);
            setIsHoveredIcon(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
            setIsHoveredIcon(false);
          }}
          // style={{ background: `${linksSettings?.background || ""}` }}
        >
          <div style={{ width: "40px", height: "40px" }}>
            {link.thumbnail && (
              <img
                src={DriveUrl(link.thumbnail)}
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "100%",
                }}
                alt=""
              />
            )}
          </div>

          {link.isSecure ? (
            <div className="m-2">
              <a
                href="#"
                // onClick={(e) => {
                //   e.preventDefault();
                //   clickInfo(link._id, link.link);
                //   handleOpen(link.description);
                //   handleRedirectToIframe(link.link);
                // }}
                onClick={(e) => {
                  e.preventDefault();
                  handleLinkClick(link._id, link.link, link.description);
                }}
                style={{
                  textDecoration: "none",
                  color: `${linksSettings?.color || ""}`,
                  fontSize: `${linksSettings?.size || ""}`,
                  fontFamily: `${linksSettings?.family || ""}`,
                }}
                rel="noreferrer"
              >
                <h6
                  style={
                    isHovered ? linkHeadingHoverStyle : linkHeadingBaseStyle
                  }
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  {link.description}
                </h6>
              </a>
            </div>
          ) : (
            <div className="m-2">
              <a
                href={link.link}
                target="_blank"
                onClick={() => clickInfo(link._id, link.link)}
                style={{
                  textDecoration: "none",
                  color: `${linksSettings?.color || ""}`,
                  fontSize: `${linksSettings?.size || ""}`,
                  fontFamily: `${linksSettings?.family || ""}`,
                }}
                rel="noreferrer"
              >
                <h6
                  style={
                    isHovered ? linkHeadingHoverStyle : linkHeadingBaseStyle
                  }
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  {link.description}
                </h6>
              </a>
            </div>
          )}
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "40px", height: "40px", borderRadius: "100%" }}
          >
            {link.linkType === "Normal Link" ? (
              ""
            ) : (
              <button
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  color: `${linksSettings?.color || ""}`,
                }}
                onClick={() => {
                  if (!shown) {
                    clickInfo(link._id, link.instantLink);
                  }
                  setShown(!shown);
                }}
              >
                {shown ? (
                  <Icon.ChevronUp
                    size={20}
                    style={isHoveredIcon ? hoverIconStyle : iconBaseStyle}
                    onMouseEnter={() => setIsHoveredIcon(true)}
                    onMouseLeave={() => setIsHoveredIcon(false)}
                  />
                ) : (
                  <Icon.ChevronDown
                    size={20}
                    style={isHoveredIcon ? hoverIconStyle : iconBaseStyle}
                    onMouseEnter={() => setIsHoveredIcon(true)}
                    onMouseLeave={() => setIsHoveredIcon(false)}
                  />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
      {shown ? <Renderlink link={link} /> : null}

      {/* ifream modal  */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          className="ifreamVideoBox"
          sx={{
            mt:
              orientation === "landscape"
                ? deviceType === "mobile" || deviceType === "tablet"
                  ? "50px"
                  : ""
                : orientation === "portrait" &&
                  (deviceType === "mobile" || deviceType === "tablet")
                ? "50px"
                : "",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
            // p: 1,
            borderRadius: "5px",
            // height: "auto",
          }}
        >
          {iframeUrl && (
            <div id="report" style={{ width: "100%" }}>
              <div
                id="navbar"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  background: "#BED900",
                  padding: "22px 10px 10px 10px",
                  height: "68px",
                  margin: "0px",
                }}
              >
                <div>
                  <button
                    onClick={() => {
                      setIframeUrl("");
                      handleClose();
                    }}
                    style={{
                      display: "flex",
                      width: "102px",
                      height: "41px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "4px",
                      borderRadius: "34px",
                      background: "#262B00",
                      color: "#fff",
                      fontSize: "20px",
                      cursor: "pointer",
                      fontWeight: 500,
                    }}
                    className="ifream-back-button"
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                      >
                        <path
                          d="M18.5 7.5L13.5 12.5L18.5 17.5"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.5 7.5L6.5 12.5L11.5 17.5"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <span>Back</span>
                  </button>
                </div>

                {/* <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      border: "1px solid red",
                    }}
                  >
                    <IoMdArrowBack />
                    <span
                      style={{ fontSize: "12px", letterSpacing: "0.48px" }}
                      className="fas"
                    >
                      Back
                    </span>
                  </div> */}
                {/* </div> */}
                <div style={{ fontWeight: 500, fontSize: "18px" }}>
                  {titleName}
                </div>
                <div></div>
              </div>
              <iframe
                id="report_iframe"
                src={iframeUrl}
                name="iframe_a"
                width="100%"
                height="800px"
                style={{
                  background: "#fff",
                  color: "black",
                  height: "calc(100vh - 50px)",
                  border: "none",
                }}
                title="Title"
              >
                Loading...
              </iframe>
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default Link;
